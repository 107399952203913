<template>
    <div class="pricing relative ">
        <!--        <img :src="pattern" alt="" class="object-cover inset-0 absolute z-0 w-full h-full"/>-->

        <div class="gm-padding-y">
            <div class="container relative z-10 pb-16">
                <div class="">
                    <h3 class="text-3xl lg:text-4xl font-bold text-gray-800 text-center">
                        Plans and Pricing
                    </h3>
                </div>

                <div class="-mx-4 px-6 text-center py-4" id="pricing">
                    <div
                        class="max-w-full flex mx-auto my-12 justify-center duration-300 gap-6 lg:gap-4 flex-col md:flex-row">
                        <ProductBase :is-setup="false"/>
                        <ProductBusiness :is-setup="false"/>
                        <ProductBusinessPro :is-setup="false"/>
                        <ProductCustomPlan :is-setup="false"/>
                    </div>

                    <!--                    <ProductOnboarding/>-->

                    <!--                    <ProductCustom/>-->

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {router, usePage} from "@inertiajs/vue3";
import ProductBase from "@/Pages/Index/Pricing/ProductBase.vue";
import ProductBusinessPro from "@/Pages/Index/Pricing/ProductBusinessPro.vue";
import ProductBusiness from "@/Pages/Index/Pricing/ProductBusiness.vue";
import ProductOnboarding from "@/Pages/Index/Pricing/ProductOnboarding.vue";
import ProductCustomPlan from "@/Pages/Index/Pricing/ProductCustomPlan.vue";


const products = usePage().props.products

</script>
